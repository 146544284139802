import React from "react";
import { FormattedMessage } from "react-intl";

function UnderDevelopment() {
  return (
    <section className="hero is-light is-bold is-fullheight is-large">
      <div className="hero-body">
        <div className="container has-text-centered">
          <p className="title is-head">
            <FormattedMessage
              id="app.shared.underdevelopment.title"
              defaultMessage="Sorry, this section is under development"
            />
          </p>
          <p className="subtitle is-1">
            <FormattedMessage
              id="app.shared.underdevelopment.subtitle"
              defaultMessage="Soon will be available, come back later"
            />
          </p>
        </div>
      </div>
    </section>
  );
}

export default UnderDevelopment;
