import React from "react";
import { FormattedMessage } from "react-intl";

import NavBar from "../../components/elements/NavBar";
import NavBarItem from "../../components/elements/NavBarItem";
import HasFeature from "../../components/helpers/HasFeature";

/**
 *
 * @param path
 * @returns {Function}
 */
function isPathActive(path) {
  return function (match, location) {
    if (!location) {
      return false;
    }
    return location.pathname.startsWith(path);
  };
}
function Connect4iNavBar() {
  return (
    <NavBar>
      {/* MACHINES */}
      <HasFeature feature="C4I:CONFIGURATION">
        <NavBarItem
          to="/connect4i/configuration/machines"
          isActive={isPathActive("/connect4i/configuration")}
          data-cy="navbar-settings-general"
        >
          <span className="icon is-small">
            <i className="icon-st-machines-icn" />
          </span>
          <FormattedMessage
            id="app.components.navigation.c4i.machines"
            defaultMessage="Machines"
          />
        </NavBarItem>
      </HasFeature>
      {/* LOGS */}
      <HasFeature feature="C4I:LOGS">
        <NavBarItem to="/connect4i/logs" data-cy="navbar-settings-general">
          <span className="icon is-small">
            <i className="icon-sc-logs-icn" />
          </span>
          <FormattedMessage
            id="app.components.navigation.c4i.logs"
            defaultMessage="Logs"
          />
        </NavBarItem>
      </HasFeature>
      {/* PROGRAMS */}
      <HasFeature feature="C4I:PROGRAMS">
        <NavBarItem to="/connect4i/programs" data-cy="navbar-settings-general">
          <span className="icon is-small">
            <i className="icon-st-programs-icn" />
          </span>
          <FormattedMessage
            id="app.components.navigation.c4i.programs"
            defaultMessage="Programs"
          />
        </NavBarItem>
      </HasFeature>
      {/* RESOURCES-MONITORING */}
      <HasFeature feature="C4I:CONSUMPTIONS">
        <NavBarItem
          to="/connect4i/consumptions/dashboard"
          data-cy="navbar-settings-general"
        >
          <span className="icon is-small">
            <i className="icon-giphy-money" />
          </span>
          <FormattedMessage
            id="app.components.navigation.c4i.consumptions"
            defaultMessage="Consumptions"
          />
        </NavBarItem>
      </HasFeature>
    </NavBar>
  );
}

Connect4iNavBar.defaultProps = {};
Connect4iNavBar.propTypes = {};
export default Connect4iNavBar;
