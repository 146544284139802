import React from "react";
import { FormattedMessage } from "react-intl";

import HasPermission from "../../components/helpers/HasPermission";
import NavBar from "../../components/elements/NavBar";
import NavBarItem from "../../components/elements/NavBarItem";

/**
 *
 * @param path
 * @returns {Function}
 */
function isPathActive(path) {
  return function (match, location) {
    if (!location) {
      return false;
    }
    return location.pathname.startsWith(path);
  };
}
function SettingsNavBar() {
  return (
    <NavBar>
      <NavBarItem
        to="/settings/configuration/general"
        isActive={isPathActive("/settings/configuration")}
        data-cy="navbar-settings-general"
      >
        <span className="icon is-small">
          <i className="icon-st-configuration-icn" />
        </span>
        <FormattedMessage
          id="app.layout.settings.navbar.configuration"
          defaultMessage="Configuration"
        />
      </NavBarItem>
      <HasPermission permissions={["ADMIN"]}>
        <NavBarItem to="/settings/users" data-cy="navbar-settings-users">
          <span className="icon is-small">
            <i className="icon-st-users-icn" />
          </span>
          <FormattedMessage
            id="app.layout.settings.navbar.users"
            defaultMessage="Users"
          />
        </NavBarItem>
      </HasPermission>
      <NavBarItem to="/settings/about" data-cy="navbar-settings-about">
        <span className="icon is-small">
          <i className="icon-st-about-icn" />
        </span>
        <FormattedMessage
          id="app.layout.settings.navbar.about"
          defaultMessage="About"
        />
      </NavBarItem>
    </NavBar>
  );
}

SettingsNavBar.defaultProps = {};
SettingsNavBar.propTypes = {};
export default SettingsNavBar;
