import React from "react";
import { FormattedMessage } from "react-intl";

import NavBar from "../../components/elements/NavBar";
import NavBarItem from "../../components/elements/NavBarItem";
import HasFeature from "../../components/helpers/HasFeature";

function HistoryNavBar() {
  return (
    <NavBar>
      <div className="navbar-start">
        <NavBarItem to="/history/jobs" data-cy="navbar-history-jobs">
          <span className="icon is-small">
            <i className="icon-fv-jobs-icn" />
          </span>
          <FormattedMessage
            id="app.pages.history.navbar.production_orders"
            defaultMessage="Production orders"
          />
        </NavBarItem>
        <HasFeature feature={"HISTORY:WORKERS"}>
          <NavBarItem to="/history/workers" data-cy="navbar-history-staff">
            <span className="icon is-small">
              <i className="icon-fv-operators-icn" />
            </span>
            <FormattedMessage
              id="app.pages.scheduling.navbar.staff"
              defaultMessage="Personale"
            />
          </NavBarItem>
        </HasFeature>
        <HasFeature feature={"HISTORY:COMPARE"}>
          <NavBarItem to="/history/compare" data-cy="navbar-history-staff">
            <span className="icon is-small">
              <i className="icon-award" />
            </span>
            <FormattedMessage
              id="app.pages.history.navbar.compare"
              defaultMessage="Compare"
            />
          </NavBarItem>
        </HasFeature>
        <HasFeature feature={"HISTORY:STAFF"}>
          <NavBarItem to="/history/staff" data-cy="navbar-history-calendar">
            <span className="icon is-small">
              <i className="icon-giphy-calendar" />
            </span>
            <FormattedMessage
              id="app.pages.scheduling.navbar.calendar"
              defaultMessage="Calendar"
            />
          </NavBarItem>
        </HasFeature>

        {/*<NavBarItem to="/history/items" data-cy="navbar-history-items">*/}
        {/*  <span className="icon is-small">*/}
        {/*    <i className="icon-sc-catalog-icn" />*/}
        {/*  </span>*/}
        {/*  <FormattedMessage id="app.pages.history.navbar.items" defaultMessage="Items" />*/}
        {/*</NavBarItem>*/}
        {process.env.CUSTOMER === "it-sssn" && (
          <NavBarItem to="/history/telediagnosis_machinery">
            <span className="icon is-small">
              <i className="icon-st-machines-icn" />
            </span>
            <FormattedMessage
              id="app.pages.history.navbar.telediagnosis_machinery"
              defaultMessage="Telediagnosis Machinery"
            />
          </NavBarItem>
        )}
      </div>
    </NavBar>
  );
}

HistoryNavBar.defaultProps = {};
HistoryNavBar.propTypes = {};
export default HistoryNavBar;
