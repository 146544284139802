import React from "react";
import PropTypes from "prop-types";
import { Redirect, Route, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

/**
 * Protect route checking if user is authenticated
 * @param permissions
 * @param rest
 * @constructor
 */
function RoutePrivate({ permissions, ...rest }) {
  const user = useSelector(({ user }) => user.user);
  const location = useLocation();

  if (!user) {
    return (
      <Redirect to={{ pathname: "/errors/401", state: { from: location } }} />
    );
  }
  if (
    permissions &&
    permissions.every(
      (permission) => !user.user.permissions.includes(permission)
    )
  ) {
    return (
      <Redirect to={{ pathname: "/errors/401", state: { from: location } }} />
    );
  }

  return <Route {...rest} />;
}

RoutePrivate.defaultProps = {};
RoutePrivate.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
};

export default RoutePrivate;
