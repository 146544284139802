import React from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import styled, { ThemeProvider } from "styled-components";
import themes from "../../libs/themes";

/// Starting style for every tooltip
const StyledTooltip = styled(ReactTooltip)`
  opacity: 1 !important;
  margin-top: -1rem !important;
  font-size: 1.4rem;
  background-color: ${({ theme }) => theme.bg} !important;
  color: ${({ theme }) => theme.fg} !important;
  &:after {
    border-right-color: ${({ theme }) => theme.bg} !important;
  }
`;

/**
 * Tooltip
 *
 * @param {*} id - must match the "data-for" attribute
 * @param {*} place - position right|left|top|bottom
 * @param theme
 */
function Tooltip({ id, place, theme }) {
  return (
    <ThemeProvider theme={theme}>
      <StyledTooltip id={id} place={place} effect="solid" delayHide={200} />
    </ThemeProvider>
  );
}

Tooltip.defaultProps = {
  theme: themes.productionBlue,
};
Tooltip.propTypes = {
  id: PropTypes.string.isRequired,
  place: PropTypes.oneOf(["right", "left", "top", "bottom"]).isRequired,
  theme: PropTypes.shape({
    bg: PropTypes.string.isRequired,
    fg: PropTypes.string.isRequired,
  }),
};

export default Tooltip;
