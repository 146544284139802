import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

function NavBarItem({ to, children, ...rest }) {
  return (
    <NavLink to={to} className="navbar-item" activeClassName="active" {...rest}>
      {children}
    </NavLink>
  );
}

NavBarItem.defaultProps = {};
NavBarItem.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node,
};
export default NavBarItem;
