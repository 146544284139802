/*
 * action types
 */
export const SET_USER = "SET_USER";
export const SET_MACHINES = "SET_MACHINES";
export const SET_MANAGER_CONFIGURATION = "SET_MANAGER_CONFIGURATION";
export const SET_FEATURES = "SET_FEATURES";
export const RESET_CONFIG = "RESET_CONFIG";
/*
 * action creators
 */
export function setUser(user = null) {
  return { type: SET_USER, user };
}
export function setMachines(machines = []) {
  return { type: SET_MACHINES, machines };
}

export function resetConfig() {
  return { type: RESET_CONFIG };
}

export function setManagerConfiguration(value = null) {
  return { type: SET_MANAGER_CONFIGURATION, value };
}

export function setFeatures(value = []) {
  return { type: SET_FEATURES, value };
}
