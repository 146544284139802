import React from 'react'
import {FormattedMessage} from 'react-intl'

import NavBar from '../../components/elements/NavBar'
import NavBarItem from '../../components/elements/NavBarItem'
import HasFeature from '../../components/helpers/HasFeature'

function StockNavBar() {
  return (
    <NavBar>
      <div className="navbar-start">
        <HasFeature feature="STOCK:CONTAINERS">
          <NavBarItem to="/stock/containers" data-cy="navbar-stock-containers">
          <span className="icon is-small">
            <i className="icon-sc-containers-icn" />
          </span>
            <FormattedMessage
              id="app.pages.stock.navbar.containers"
              defaultMessage="Containers"
            />
          </NavBarItem>
        </HasFeature>
        <HasFeature feature="STOCK:MOVEMENTS">
          <NavBarItem to="/stock/movements" data-cy="navbar-stock-movements">
          <span className="icon is-small">
            <i className="icon-swap-icn" />
          </span>
            <FormattedMessage
              id="app.pages.stock.navbar.movements"
              defaultMessage="Movements"
            />
          </NavBarItem>
        </HasFeature>
      </div>
    </NavBar>
  )
}

StockNavBar.defaultProps = {}
StockNavBar.propTypes = {}
export default StockNavBar
