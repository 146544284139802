import React from "react";
import PropTypes from "prop-types";
import { NavLink, useHistory } from "react-router-dom";
import Helmet from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";

import { languageWithoutRegionCode as lang } from "../config/localization";
import { setUser } from "../actions";
import confirmAction from "../libs/confirm-action";

function HomeLayout({ children }) {
  const user = useSelector(({ user }) => user.user);
  const history = useHistory();
  const dispatch = useDispatch();

  function logout() {
    function confirm() {
      dispatch(setUser());
      history.push("/");
    }
    return confirmAction({ confirm });
  }
  return (
    <section className="hero is-primary is-large is-bold is-fullheight">
      <Helmet htmlAttributes={{ class: "home-layout", lang }} />
      <div className="hero-head">
        <header className="nav">
          <div className="container">
            <nav className="navbar is-transparent">
              <div className="navbar-menu">
                <div className="navbar-end">
                  <NavLink
                    to="/"
                    activeClassName="is-active"
                    exact
                    className="navbar-item"
                    data-cy="navlink-home"
                  >
                    <FormattedMessage
                      id="app.layout.home.home.button"
                      defaultMessage="Home"
                    />
                  </NavLink>
                  <Choose>
                    <When condition={user}>
                      <NavLink
                        to="/scheduling/jobs"
                        activeClassName="is-active"
                        exact
                        className="navbar-item"
                        data-cy="navlink-dashboard"
                      >
                        <FormattedMessage
                          id="app.layout.home.dashboard.button"
                          defaultMessage="Dashboard"
                        />
                      </NavLink>
                      <a
                        className="navbar-item"
                        onClick={logout}
                        data-cy="navlink-signout"
                      >
                        <FormattedMessage
                          id="app.layout.home.signout.button"
                          defaultMessage="Sign out"
                        />
                      </a>
                    </When>
                    <Otherwise>
                      <NavLink
                        to="/authentication/login"
                        activeClassName="is-active"
                        exact
                        className="navbar-item"
                        data-cy="navlink-signin"
                      >
                        <FormattedMessage
                          id="app.layout.home.signin.button"
                          defaultMessage="Sign in"
                        />
                      </NavLink>
                    </Otherwise>
                  </Choose>
                </div>
              </div>
            </nav>
          </div>
        </header>
      </div>
      {children}
      <div className="hero-foot">
        <div className="container">
          <div
            className="content has-text-right"
            style={{ marginBottom: ".5rem" }}
          >
            <p>
              <FormattedMessage
                id="app.layout.home.made_by"
                defaultMessage="Made in Italy with <span>❤</span> by <a>Industrial Cloud</a>"
                values={{
                  span: (...chunks) => (
                    <span role="img" aria-label="love">
                      {chunks}
                    </span>
                  ),
                  a: (...chunks) => (
                    <a
                      className="fg-white"
                      href="https://www.industrial-cloud.com"
                    >
                      {chunks}
                    </a>
                  ),
                }}
              />
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

HomeLayout.propTypes = {
  children: PropTypes.node,
};
export default HomeLayout;
