import React from "react";
import { FormattedMessage } from "react-intl";

import NavBar from "../../components/elements/NavBar";
import NavBarItem from "../../components/elements/NavBarItem";

function LiveNavBar() {
  return (
    <NavBar>
      <NavBarItem to="/live/jobs" data-cy="navbar-live-jobs">
        <span className="icon is-small">
          <i className="icon-fv-jobs-icn" />
        </span>
        <FormattedMessage
          id="app.pages.factory.navbar.production_orders"
          defaultMessage="Production orders"
        />
      </NavBarItem>
      <NavBarItem to="/live/stations" data-cy="navbar-live-stations">
        <span className="icon is-small">
          <i className="icon-fv-grid-icn" />
        </span>
        <FormattedMessage
          id="app.pages.factory.navbar.stations"
          defaultMessage="Stations"
        />
      </NavBarItem>
      <NavBarItem to="/live/machines" data-cy="navbar-live-machines">
        <span className="icon is-small">
          <i className="icon-st-machines-icn" />
        </span>
        <FormattedMessage
          id="app.pages.factory.navbar.machines"
          defaultMessage="Machines"
        />
      </NavBarItem>
      <NavBarItem to="/live/workers" data-cy="navbar-live-workers">
        <span className="icon is-small">
          <i className="icon-fv-operators-icn" />
        </span>
        <FormattedMessage
          id="app.pages.factory.navbar.workers"
          defaultMessage="Staff"
        />
      </NavBarItem>
    </NavBar>
  );
}

LiveNavBar.defaultProps = {};
LiveNavBar.propTypes = {};
export default LiveNavBar;
