import React from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage} from 'react-intl'

import NavBar from '../../components/elements/NavBar'
import NavBarItem from '../../components/elements/NavBarItem'
import HasFeature from '../../components/helpers/HasFeature'

function ListingsNavBar() {
  return (
    <NavBar>
      <div className="navbar-start">
        <NavBarItem to="/listings/items" data-cy="navbar-listings-items">
          <span className="icon is-small">
            <i className="icon-sc-catalog-icn" />
          </span>
          <FormattedMessage
            id="app.pages.listings.navbar.catalog"
            defaultMessage="Items"
          />
        </NavBarItem>
        <NavBarItem
          to="/listings/customers"
          data-cy="navbar-listings-customers"
        >
          <span className="icon is-small">
            <i className="icon-sc-customers-icn" />
          </span>
          <FormattedMessage
            id="app.pages.listings.navbar.customers"
            defaultMessage="Customers"
          />
        </NavBarItem>
        <NavBarItem
          to="/listings/suppliers"
          data-cy="navbar-listings-suppliers"
        >
          <span className="icon is-small">
            <i className="icon-sc-suppliers-icn" />
          </span>
          <FormattedMessage
            id="app.pages.listings.navbar.suppliers"
            defaultMessage="Suppliers"
          />
        </NavBarItem>
        <NavBarItem to="/listings/safety" data-cy="navbar-listings-safety">
          <span className="icon is-small">
            <i className="icon-st-safety-icn" />
          </span>
          <FormattedMessage
            id="app.pages.listings.navbar.safety"
            defaultMessage="Safety cards"
          />
        </NavBarItem>
        <HasFeature feature="BATCHES">
          <NavBarItem to="/listings/batches"
                      data-cy="navbar-listings-templates">
          <span className="icon is-small">
            <i className="icon-sc-catalog-icn" />
          </span>
            <FormattedMessage
              id="app.pages.listings.navbar.batches"
              defaultMessage="Lotti"
            />
          </NavBarItem>
        </HasFeature>
        <NavBarItem to="/listings/resources" data-cy="navbar-listings-resources">
          <span className="icon is-small">
            <i className="icon-giphy-money" />
          </span>
          <FormattedMessage
            id="app.pages.listings.navbar.resources"
            defaultMessage="Resources"
          />
        </NavBarItem>
      </div>
    </NavBar>
  )
}

ListingsNavBar.defaultProps = {
  onRefresh: () => true
}
ListingsNavBar.propTypes = {
  onRefresh: PropTypes.func
}
export default ListingsNavBar
